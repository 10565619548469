import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const authUserInitialState: IAuthUserSlice = {
  user: { role: {} } as IAuthUser,
  verifier: "",
  licenseKey: "",
};

export const authUserSlice = createSlice({
  name: "user",
  initialState: authUserInitialState,
  reducers: {
    loginUser: (state: IAuthUserSlice, { payload }: PayloadAction<IAuthUser>) => {
      state.user = { ...payload, role: { ...payload.role } };
    },
    logoutUser: (state: IAuthUserSlice) => {
      state.user = { role: {} } as IAuthUser;
      state.verifier = "";
    },
    // updateCurrentUser: (state: UserSlice, { payload }: PayloadAction<IEditUserResponseData>) => {
    //   state.user = { ...state.user, ...payload };
    // },
    setVerifier: (state: IAuthUserSlice, { payload }: PayloadAction<string>) => {
      state.verifier = payload;
    },
    setLicenseKey: (state: IAuthUserSlice, { payload }: PayloadAction<string>) => {
      state.licenseKey = payload;
    },
    updateAccessToken: (state: IAuthUserSlice, { payload }: PayloadAction<string>) => {
      if (state.user) {
        state.user.accessToken = payload;
      }
    },
    
  },
});

export const { loginUser, logoutUser, setVerifier, setLicenseKey , updateAccessToken } = authUserSlice.actions;

export default authUserSlice.reducer;
